// src/pages/Home.js
import React from "react";
import About from "../../components/about/about";

const AboutPage = () => (
  <div>
    <About />
  </div>
);

export default AboutPage;
