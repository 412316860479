// src/pages/Home.js
import React from "react";
import Hero from "../../components/hero/hero";

const HomePage = () => (
  <div>
    <Hero />
  </div>
);

export default HomePage;
