import React from "react";
import { NavLink } from 'react-router-dom';
// SCSS
import "./navbar.scss";
// Assets
import CloseIcons from '../../assets/navbar/mobile-close.svg';
import { FaFacebookF, FaTwitter, FaInstagram, FaSpotify, FaTiktok, FaMusic } from 'react-icons/fa';

const mobileNav = (props) => (
  <div className={`mobile__navbar ${props.isOpen ? "mobile__open" : ""}`}>
    <div className="mobile__navbar-close" onClick={props.closeMobileMenu}>
      <img src={CloseIcons} alt="close" />
    </div>
    <div className="mobile__navbar-menu">
      <ul>
      <li className="flex-center">
          <NavLink
            className={(navData) => (navData.isActive ? "active-link" : 'none')}
            to="/"
            offset={-70}
            duration={500}
            onClick={props.closeMobileMenu}
          >
            HOME
          </NavLink>
        </li>
        <li className="flex-center">
          <NavLink
            className={(navData) => (navData.isActive ? "active-link" : 'none')}
            to="/about"
            offset={-70}
            duration={500}
            onClick={props.closeMobileMenu}
          >
            ABOUT
          </NavLink>
        </li>
        <li className="flex-center">
          <NavLink
            className={(navData) => (navData.isActive ? "active-link" : 'none')}
            to="/music"
            offset={-70}
            duration={500}
            onClick={props.closeMobileMenu}
          >
            MUSIC
          </NavLink>
        </li>
        <li className="flex-center">
          <NavLink
            className={(navData) => (navData.isActive ? "active-link" : 'none')}
            to="/merch"
            offset={-70}
            duration={500}
            onClick={props.closeMobileMenu}
          >
            MERCH
          </NavLink>
        </li>
        <li className="flex-center">
          <NavLink
            className={(navData) => (navData.isActive ? "active-link" : 'none')}
            to="/contact"
            offset={-70}
            duration={500}
            onClick={props.closeMobileMenu}
          >
            CONTACT
          </NavLink>
        </li>
        <li className="flex-center socials">
          <a href="https://www.instagram.com/barelystrangersband" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.tiktok.com/@barely.strangers" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="https://www.facebook.com/Barelystrangers" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://x.com/BarelyStranger" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            {/* <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a> */}
            <a href="https://open.spotify.com/artist/13bCFab8xJQrFNfm1RdDQr?si=a-Xyg_beTuO0zAZWAtI7cw" target="_blank" rel="noopener noreferrer">
              <FaSpotify />
            </a>
            <a href="https://distrokid.com/hyperfollow/barelystrangers/mess-around" target="_blank" rel="noopener noreferrer">
              <FaMusic />
            </a>
        </li>
      </ul>
    </div>
  </div>
);

export default mobileNav;
