import React from 'react';
import { NavLink } from 'react-router-dom';
 // SCSS
import './navbar.scss';
// Assets
// import LogoImg from '../../assets/navbar/logo.jpg';
import MobileMenuIcon from '../../assets/navbar/mobile-menu.svg';
import { FaFacebookF, FaTwitter, FaInstagram, FaSpotify, FaTiktok, FaMusic } from 'react-icons/fa';



const desktopNav = (props) => (
  <nav className={`Navbar ${!props.userIsScrolled ? "extraLargeNavbar" : ""}`}>
    <div className="wrapper flex-s-between">
      {/* <NavLink to="/" offset={0} duration={500}><img src={LogoImg} alt="logo" className="pointer" /></NavLink> */}
      <div className="mobile__menu" onClick={props.mobileMenuOpen}>
        <img src={MobileMenuIcon} alt="menu" />
      </div>
      <div className="desktop__menu">
        <ul className="flex-s-between">
          <li>
            <NavLink className={(navData) => (navData.isActive ? "active-link" : 'none')} to="/" offset={-70} duration={500}>HOME</NavLink>
          </li>
          <li>
            <NavLink className={(navData) => (navData.isActive ? "active-link" : 'none')} to="/about" offset={-70} duration={500}>ABOUT</NavLink>
          </li>
          <li>
            <NavLink className={(navData) => (navData.isActive ? "active-link" : 'none')} to="/music" offset={-70} duration={500}>MUSIC</NavLink>
          </li>
          <li>
            <NavLink className={(navData) => (navData.isActive ? "active-link" : 'none')} to="/merch" offset={-70} duration={500}>MERCH</NavLink>
          </li>

          <li>
            <NavLink className={(navData) => (navData.isActive ? "active-link" : 'none')} to="/contact" offset={-70} duration={500}>CONTACT</NavLink>
          </li>
        </ul>
      </div>
      <div className="flex-center socials-desktop">
          <a href="https://www.instagram.com/barelystrangersband" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.tiktok.com/@barely.strangers" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="https://www.facebook.com/Barelystrangers" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://x.com/BarelyStranger" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            {/* <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a> */}
            <a href="https://open.spotify.com/artist/13bCFab8xJQrFNfm1RdDQr?si=a-Xyg_beTuO0zAZWAtI7cw" target="_blank" rel="noopener noreferrer">
              <FaSpotify />
            </a>
            <a href="https://distrokid.com/hyperfollow/barelystrangers/mess-around" target="_blank" rel="noopener noreferrer">
              <FaMusic />
            </a>
        </div>
    </div>
  </nav>
);

export default desktopNav;