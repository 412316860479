import React from "react";
import { FaFacebookF, FaTwitter, FaInstagram, FaSpotify, FaTiktok, FaMusic } from 'react-icons/fa';
import "./footer.scss";


const partnerBox = () => (
  <div className="footer">
    <div className="wrapper">
          <div className="footer-box social-media">
            <a href="https://www.instagram.com/barelystrangersband" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.tiktok.com/@barely.strangers" target="_blank" rel="noopener noreferrer">
              <FaTiktok />
            </a>
            <a href="https://www.facebook.com/Barelystrangers" target="_blank" rel="noopener noreferrer">
              <FaFacebookF />
            </a>
            <a href="https://x.com/BarelyStranger" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            {/* <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a> */}
            <a href="https://open.spotify.com/artist/13bCFab8xJQrFNfm1RdDQr?si=a-Xyg_beTuO0zAZWAtI7cw" target="_blank" rel="noopener noreferrer">
              <FaSpotify />
            </a>
            <a href="https://distrokid.com/hyperfollow/barelystrangers/mess-around" target="_blank" rel="noopener noreferrer">
              <FaMusic />
            </a>
          </div>
      <p className="legal">@BARELY STRANGERS 2024</p>
    </div>
  </div>
);

export default partnerBox;
