// src/pages/Home.js
import React from "react";
import ComingSoon from "../../components/coming-soon/coming-soon";
// import Portfolio from "../../components/portfolio/portfolio"

const MerchPage = () => (
  <ComingSoon />
  // <Portfolio />
);

export default MerchPage;
