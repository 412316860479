// src/pages/Home.js
import React from "react";
import Music from "../../components/music/music";

const MusicPage = () => (
  <div>
    <Music />
  </div>
);

export default MusicPage;
