import React from "react";
import { Row, Col } from "react-bootstrap";
import "./contact.scss";
import * as emailjs from "emailjs-com";
import Title from "../ui-components/title/title";
import ContactInfo from './contactInfo/contactInfo';
import Modal from '../contact-modal/Modal';
import ContactBackground from '../../assets/contact/bg.png';

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      sending: false,
      successModal: false,
      errorModal: false,
    };
  }

  inputHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ sending: true });

    const templateParams = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    };

    // YOUR EMAIL.JS USER ID (formerly API Key)
    const USER_ID = "Sr-lUruRNjMvOYYcv"; // Update with your EmailJS User ID

    // YOUR EMAIL.JS TEMPLATE ID
    const TEMPLATE_ID = "template_dblgo87"; // Update with your EmailJS Template ID

    // YOUR EMAIL.JS SERVICE ID
    const SERVICE_ID = "service_pkl4xwa"; // Update if you have a custom service ID

    emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID).then(
      (response) => {
        if (response.status === 200) {
          this.showSuccessModal();
        } else {
          this.showErrorModal();
        }
      },
      (error) => {
        this.showErrorModal();
        console.error("EmailJS error:", error);
      }
    );
  };

  // SUCCESS MODAL
  showSuccessModal = () => {
    this.setState({ successModal: true, sending: false });
    this.resetForm();
  };

  // ERROR MODAL
  showErrorModal = () => {
    this.setState({ errorModal: true, sending: false });
    this.resetForm();
  };

  // RESET CONTACT FORM
  resetForm = () => {
    this.setState({ name: "", email: "", message: "" });
  };

  // CLOSE ALL MODALS
  closeModal = () => {
    this.setState({ successModal: false, errorModal: false });
  };

  render() {
    const submitButtonRender = this.state.sending ? (
      <div className="small__button sending-btn">
        <div className="flex-center">
          <div className="sbl-circ"></div>
        </div>
      </div>
    ) : (
      <div className="small__button">
        <button aria-label="send message" type="submit" value="Send Message">
          Send Message
        </button>
      </div>
    );

    const modalRender = this.state.successModal ? (
      <Modal closeModal={this.closeModal} status="success" />
    ) : this.state.errorModal ? (
      <Modal closeModal={this.closeModal} status="error" />
    ) : null;

    return (
      <div id="contact">
        {modalRender}
        <div className="wrapper">
          <Title title="CONTACT US." />
          <p className="font12">
            Feel free to send us a message on social media or through our contact form.
          </p>

          <Row className="padding40">
            <Col md={12} lg={6}>
              <form id="contact-form" onSubmit={this.handleSubmit}>
                <h4 className="font30 weight800 padding30">Send us a message</h4>
                <input
                  type="text"
                  placeholder="Name"
                  required
                  name="name"
                  value={this.state.name}
                  onChange={this.inputHandler}
                />
                <input
                  type="email"
                  placeholder="Email"
                  required
                  name="email"
                  value={this.state.email}
                  onChange={this.inputHandler}
                />
                <textarea
                  rows="6"
                  cols="50"
                  placeholder="Message..."
                  required
                  name="message"
                  value={this.state.message}
                  onChange={this.inputHandler}
                ></textarea>
                {submitButtonRender}
              </form>
            </Col>
            <Col md={12} lg={6}>
              <div className="flex-center">
                <img src={ContactBackground} alt="contact background" />
              </div>
            </Col>
          </Row>
          <ContactInfo />
        </div>
      </div>
    );
  }
};

export default Contact;
