import React from "react";
import { Row, Col } from "react-bootstrap";
import "./about.scss";
// Components
import TeamBox from './teamBox';
import Title from "../ui-components/title/title";
// Assets
import Person01 from "../../assets/about/pat.jpg";
import Person02 from "../../assets/about/sam.jpg";
import Person03 from "../../assets/about/tyler.jpg";

const About = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="ABOUT US" />
      <p className="font12">
        Emerging from Manchester's music scene, is an indie rock group comprised of Patrick Ryan and Sam West, with Tyler Bolton joining on drums.
        <br /><br />
        They create a sound that is recorded, mixed and mastered in their basement studio. This DIY ethic ensures their music remains true to their artistic spirit of Manchester's musical roots.
      </p>
      <Row>
        <Col md={12} lg={4}>
          <TeamBox avatar={Person01} name="Pat Ryan" job="Singer / Songwriter" />
        </Col>
        <Col md={12} lg={4}>
          <TeamBox avatar={Person02} name="Sam West" job="Multi-instrumentalist / Songwriter" />
        </Col>
        <Col md={12} lg={4}>
          <TeamBox avatar={Person03} name="Tyler Bolton" job="Drummer" />
        </Col>
      </Row>
    </div>
  </div>
);

export default About;
