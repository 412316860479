// src/pages/Home.js
import React from "react";
import Contact from "../../components/contact/contact";

const ContactPage = () => (
  <div>
    <Contact />
  </div>
);

export default ContactPage;
