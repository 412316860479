import React from "react";
import "./contactInfo.scss";

import ContactInfoBox from "../contactInfo/contactInfoBox";


import ContactInfoIcon1 from '../../../assets/contact/contact-info-icon1.svg';
import ContactInfoIcon2 from "../../../assets/contact/contact-info-icon2.svg";
import ContactInfoIcon3 from "../../../assets/contact/contact-info-icon3.svg";


const contactInfo = () => (
  <div className="contact__info">
        <div className="contact__info">
          <ContactInfoBox
            icon={ContactInfoIcon1}
            textLine1="Stretford, Manchester"
          />
        </div>
        <div className="contact__info">
          <ContactInfoBox
            icon={ContactInfoIcon2}
            textLine1="+447770623879"
          />
        </div>
        <div className="contact__info">
          <ContactInfoBox
            icon={ContactInfoIcon3}
            textLine1="barelystrangersband@gmail.com"
          />
        </div>
  </div>
);

export default contactInfo;
