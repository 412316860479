import React from "react";
import { Row, Col } from "react-bootstrap";
// SCSS
import "./coming-soon.scss";


const comingSoon = () => (
  <div className="hero" id="hero">
    <div className="wrapper">
      <Row>
        <Col md={12} lg={6}>
          <div className="hero-info">
            <h1 className="weight800 font60">Coming soon...</h1>
            <br/>            

          </div>
        </Col>
      </Row>
    </div>
  </div>
);

export default comingSoon;
